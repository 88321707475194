#publicVenue__wrapper {
  background-color: $system-white;
  display: flex;
  width: 90%;
  max-height: 90%;
  align-self: center;
  margin: 5% 0;
  flex-wrap: wrap;
  border-radius: 12px;
  overflow: scroll;
  align-content: flex-start;

  #publicVenue__wrapper__header {
    background-color: #000;
    background-size: cover;
    background-position: center;
    height: 200px;
    flex: 1 0 100%;
  }

  #publicVenue__wrapper__body {
    flex: 1 0 100%;
    padding: 50px;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;

    #publicVenue__wrapper__logo {
      flex: 1 0 25%;
      img {
        height: 150px;
        width: 150px;
      }
    }
    #publicVenue__wrapper__info {
      flex: 1 0 75%;
      #publicVenue__wrapper__info__street0,
      #publicVenue__wrapper__info__street1,
      #publicVenue__wrapper__info__PhoneNumber,
      #publicVenue__wrapper__info__Website {
        color: $system-black;
        font-size: 14px;
        font-family: $font__helvetica;
        display: block;
      }

      #publicVenue__wrapper__info__city,
      #publicVenue__wrapper__info__state,
      #publicVenue__wrapper__info__ZipCode {
        color: $system-black;
        font-size: 14px;
        font-family: $font__helvetica;
        // display: block;
      }
    }
    #publicVenue__wrapper__services {
      flex: 1 0 100%;
      display: flex;
      flex-wrap: wrap;

      h1 {
        flex: 1 0 100%;
      }

      .publicVenue__wrapper__services__service {
        border: 1px solid #000;
        flex: 1 0 25%;
        margin: 0 15px;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        overflow: hidden;
        flex-wrap: wrap;

        .publicVenue__wrapper__services__service__header {
          height: 100px;
          flex: 1 0 100%;
          background-position: center;
          background-size: cover;
          border-bottom: 1px solid #000;
        }
        .publicVenue__wrapper__services__service__body {
          flex: 1 0 100%;
          padding: 15px;
        }
      }
    }
    #publicVenue__wrapper__services__book {
      display: flex;
      // background-color: red;
      flex-wrap: wrap;
      flex: 1 0 100%;

      h1,
      h2 {
        flex: 1 0 100%;
      }

      .publicVenue__wrapper__services__book__header {
        flex: 1 0 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .publicVenue__wrapper__services__book__header_logo {
          flex: 1 0 25%;
          img {
            width: 100%;
            height: auto;
            border: 1px solid $system-blue;
            border-radius: 12px;
          }
        }
        .publicVenue__wrapper__services__book__header_body {
          flex: 1 0 75%;
          padding-left: 25px;
          p {
            font-family: $font__helvetica;
            color: $system-black;
            font-size: 14px;
            line-height: 14px;
          }
        }
        
      }
      .publicVenue__wrapper__services__book__inputWrapper_month,
      .publicVenue__wrapper__services__book__inputWrapper_day,
      .publicVenue__wrapper__services__book__inputWrapper_year {
        display: flex;
        flex-wrap: wrap;
        padding: 0 31px 0 0;
        flex: 33%;
        label {
          font-family: $font__helvetica;
          color: $system-black;
          font-size: 14px;
          line-height: 14px;
          flex: 1 0 100%;
          margin-bottom: 10px;
        }
        input,
        select,
        textarea {
          flex: 1 0 100%;
          border: 1px solid #90a5b1;
          border-radius: 3px;
          font-family: $font__quicksand;
          font-weight: $font__quicksand-semibold;
          font-size: 13px;
          line-height: 16px;
          padding: 9px 42px 9px 11px;
          margin-bottom: 13px;
          background-color: $system-white;
        }
      }
      .publicVenue__wrapper__services__book__inputWrapper_startTime,
      .publicVenue__wrapper__services__book__inputWrapper_endTime {
        display: flex;
        flex-wrap: wrap;
        padding: 0 31px 0 0;
        flex: 1 0 50%;
        label {
          font-family: $font__helvetica;
          color: $system-black;
          font-size: 14px;
          line-height: 14px;
          flex: 1 0 100%;
          margin-bottom: 10px;
        }
        input,
        select,
        textarea {
          flex: 1 0 33%;
          border: 1px solid #90a5b1;
          border-radius: 3px;
          font-family: $font__quicksand;
          font-weight: $font__quicksand-semibold;
          font-size: 13px;
          line-height: 16px;
          padding: 9px 42px 9px 11px;
          margin-bottom: 13px;
          background-color: $system-white;
        }
      }

      .publicVenue__wrapper__services__book__inviteGuestWrapper {
        flex: 1 0 100%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 25px;

        input, button {
          flex: 1 0 25%;
        }
      }

      .publicVenue__wrapper_services__book__inviteGuestTitleWrapper {
        flex: 1 0 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 50px;
        button {
          margin-left: 0;
        }
       
      }

      button {
        margin-left: auto;
      }
    }
  }
}

.publicVenue__modal__inputWrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 0;

  label {
    font-family: $font__helvetica;
    color: $system-black;
    font-size: 14px;
    line-height: 14px;
    flex: 1 0 100%;
    margin-bottom: 10px;
  }
  input,
  select,
  textarea {
    flex: 1 0 100%;
    border: 1px solid #90a5b1;
    border-radius: 3px;
    font-family: $font__quicksand;
    font-weight: $font__quicksand-semibold;
    font-size: 13px;
    line-height: 16px;
    padding: 9px 42px 9px 11px;
    margin-bottom: 13px;
    background-color: $system-white;
  }

  button {
    margin-left: unset !important;
  }

  .publicVenue__modal__inputWrapper__forgotPassword {
    flex: 1 0 100%;
    margin-bottom: 10px;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 400;
    line-height: 14px;
    color: #4523c1;
    font-size: 12px;
    -webkit-flex: 1 0 100%;
    flex: 1 0 100%;
    text-align: right;
  }
}

#publicVenue__button-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  button {
    margin: 0 0 10px;
    flex: 1 0 100%;
  }

}

.googleSignInOutButton {
  font-size: 15px;
  color: #000;
  display: block;

}