@import '/src/globals/fonts.scss';
@import '/src/globals/colors.scss';



.merchant__venue__navPills-wrapper {
  border: 2px solid $system-blue;
  font-size: 14px;
  // display: inline-block;
  display: flex;
  // flex: 1 1 100%;
  border-radius: 21.5px;
  align-self:flex-start;

  ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    overflow: hidden;
    z-index: 2;

    li {
      font-family: $font__quicksand;
      padding: 10px 45px;
      color: $navPills-text-passive;
      position: relative;


      &.active {
        color: $navPills-text-active;

        &:after {
          content: '';
          width: 130%;
          height: 100%;
          background-color: $system-blue;
          display: block;
          position: absolute;
          top: 0;
          left: -15%;
          border-radius: 21.5px; 
        }

        span {
          z-index: 1;
          position: relative;
        }

      }
      &:first-of-type {
        border-right: 1px solid $system-blue;

        &.active {
          border: none;

          &:after {
            width:100%;
            left: 0;
          }
          
        }
      }
      &:last-of-type {
        border-left: 1px solid $system-blue;
        
        &.active {
          border: none;

          &:after {
            width:100%;
            left: 0;
          }
          
        }
      }

    }
  }
}