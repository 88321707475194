h2 {
  font-family: $font__helvetica;
  font-weight: $font__helvetica-bold;
  font-size: 16px;
  line-height: 19px;
  color: $system-black;
}
p {
  font-family: $font__helvetica;
  font-weight: 300;
  color: #A5A5A5;
  font-size: 14px;
}
label {
  font-family: $font__helvetica;
  font-weight: 400;
  color: $system-black;
  font-size: 16px;
  line-height: 19px;
}