$system-blue: #4523C1; 
$system-white: #FFFFFF;
$system-black: #000000;
$system-gray: #F4F5F4;
$system-orange: #E66E24;

$revenue-gray: #A2A2A2;
$revenue-fundraising: #D5EBF6;
$revenue-bar-ground: #DBDBDB;

$navPills-border: #27A1DD;
$navPills-text-passive: #808080;
$navPills-text-active: #F7F7F7;

$createNewService-text-passive: #A7BAC4;
$createNewService-text-active: #30A1DE;

$iconDefault: #dbdbdb;
$iconActive: #000000;

$rsvpYes: #50DE99;
$rsvpNo: #DD2769;

$button-disabled: #AB9DDF;


// Change $system-blue to the promary purple #4523C1 - go ahead and use for everything that already uses it
// TEST Action items like buttons - magenta #D12498

$splitsees-purple: #4523C1;
$splitsees-pink: #D12498;
$splitsees-black: #000;
$splitsees-white: #fff;