@import '/src/globals/fonts.scss';
@import '/src/globals/colors.scss';

#profile__wrapper {
  // background-color: #f4cde6;
  // background-color: #d4cdef;
  background-color: $system-gray;
  flex: 1 0 100%;
  border-radius: 12px;
  padding: 27px;
  display: flex;
  align-items: stretch;
  flex-direction: row-reverse;
  overflow: hidden;
  height: 100%;
}