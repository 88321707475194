@import '/src/globals/fonts.scss';
@import '/src/globals/colors.scss';

#sidebar {
  display: flex;
  flex: 1 1 21%;
  background-color: $system-blue;
  flex-direction: row-reverse;
}

#sidebar__navigation {

  list-style: none;
  margin: 142px 0 0;
  padding: 0 0 0;
  width: 93%;
  

  li {
    
    display: flex;
    align-items: center;
    border-top-left-radius: 26px;
    border-bottom-left-radius: 26px;
    padding: 5px 18px 5px 11px;
    font-family: $font__helvetica;
    font-size: 16px;
    font-weight: $font__helvetica-bold;
    color: $system-white;
    margin-bottom: 33px;
    width: calc(75% + 23px);
    margin-left: auto;



    svg:first-child {
      margin-right: 20px;
    }

    #rightChevronIcon {
      margin-left: auto;
    }

    &.active {
      background-color: $system-white;
      color: $system-blue;
      width: 100%;
    }
  }
}