// 3 Different States
// Passive - White background, used mostly for back buttons
// Active - Blue Background, used for most buttons
// Disabled - Need to get clarification from Julieta

// Hover for all 3 

// Globals
@import '/src/globals/fonts.scss';
@import '/src/globals/colors.scss';

button {
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: space-evenly;
  // max-width: 150px;


  &.button-passive {
    background-color: $system-white;
    color: $system-blue;
    font-family: $font__quicksand;
    font-weight: $font__quicksand-regular;
    font-size: 15px;
    border: none;
    border-radius: 3px;
    width: 152px;
    height: 40px;
    border: 1px solid $system-blue;
    :hover {
  
    }
  }
  
  &.button-active{
    background-color: $system-blue;
    color: $system-white;
    font-family: $font__quicksand;
    font-weight: $font__quicksand-regular;
    font-size: 15px;
    border: none;
    border-radius: 3px;
    width: 152px;
    height: 40px;
    :hover {
      
    }
    &:disabled {
      background-color: $button-disabled;
    }
  }
  
  &.button-disabled {
  
    :hover {
      
    }
  }
}


