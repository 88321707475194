// Globals
@import '/src/globals/fonts.scss';
@import '/src/globals/colors.scss';
@import './globals/beakpoints.scss';

//Components UI
@import './components/ui/button/button.scss';
@import './components/ui/revenue/revenue.scss';
@import './components/ui/header/header.scss';
@import './components/ui/sidebar/sidebar.scss';
@import './components/ui/sidebar/sidebar.scss';
@import './components/ui/mainContent/mainContent.scss';
@import './components/ui/profile/profileCard.scss';
@import './components/views/merchant/merchantProfile/profilePhotoUpload.scss';
@import './components/ui/time/timseSelect.scss';

// Views
@import './components/views/merchant/merchantProfile/merchantProfile.scss';
@import './components/views/merchant/merchantProfile/merchantProfileAccount.scss';
@import './components/views/merchant/merchantProfile/merchantProfileAccountManageVenue.scss';
@import './components/views/merchant/merchantProfile/merchantProfileAccountBankInfo.scss';
@import './components/views/merchant/merchantProfile/merchantProfileAccountBankInfo.scss';
@import './components/views/merchant/merchantProfile/merchantProfileAccountPersonalDetails.scss';
@import './components/views/merchant/merchantProfile/merchantProfileAccountChangePassword.scss';
@import './components/views/merchant/merchantProfile/merchantProfileNotifications.scss';
@import './components/views/merchant/merchantProfile/merchantProfileLegal.scss';
@import './components/views/merchant/merchantVenue/merchantVenue.scss';
@import './components/views/merchant/merchantVenue/merchantVenueAddDetails.scss';
@import './components/views/merchant/merchantVenue/merchantVenueDetailsHoursOfOperation.scss';
@import './components/views/merchant/merchantVenue/merchantVenueAddDetailsBankInformation.scss';

@import './components/views/merchant/merchantServices/merchantServicesList.scss';

@import './components/views/registerLogin/registerLoginWrapper.scss';
@import './components/views/registerLogin/register.scss';
@import './components/views/registerLogin/verify.scss';
@import './components/views/registerLogin/logIn.scss';

@import './components/views/merchant/merchantProfile/merchantProfileProfilePhoto.scss';
@import './components/views/merchant/merchantProfile/merchantProfileAccountResetPassword.scss';

@import './components/ui/modal/modal.scss';

@import './components/views/merchant/merchantVenue/merchantVenuesListVenuesLister.scss';

@import './components/views/merchant/merchantServices/merchantServices.scss';
@import './components/views/merchant/merchantServices/merchantServiceAddService.scss';
@import './components/views/publicVenue/publicVenue.scss';
@import './components/views/publicVenue/publicVenueBooking.scss';

@import './components/views/invitation/invitation.scss';
@import './components/views/invitation/invitationRsvp.scss';
@import './components/views/invitation/invitationRsvpGoing.scss';
@import './components/views/invitation/invitationRsvpNotGoing.scss';

@import './components/views/merchant/bookings/merchantBookings.scss';


/***** NEW COMPONENTS *****/
@import './components/views/frontDoor/frontDoorWrapper.scss';
@import './components/views/frontDoor/frontDoorRegister.scss';
@import './components/views/frontDoor/frontDoorVerify.scss';
@import './components/views/frontDoor/frontDoorLogIn.scss';
@import './components/views/frontDoor/frontDoorForgotPassord.scss';
@import './components/views/frontDoor/frontDoorForgotPasswordReset.scss';

@import './components/ui/splitsees-button/splitsees-button.scss';
@import './globals/beakpoints.scss';
@import './globals/inputs.scss';

html, body {
  font-size: 0px;
  margin: 0;
  height: 100%;
}

* {
  box-sizing: border-box;
}

.wrapper {
  width: 80%;
  background-color: #F4F5F4;
}

#root {
  display: flex;
  flex-direction: column;
  // flex-wrap: nowrap;
  // justify-content: flex-start;
  // align-items: stretch;
  // align-content: stretch;
  height: 100%;
  background-color: $system-blue;
  position: relative;
  z-index: 1;

}

main {
  display: flex;
  flex: 1 1 100%;
  overflow: scroll;
}

#merchantServices, #merchantVenue, #merchantBookings {
  font-family: $font__quicksand;
  font-size: 32px;
}