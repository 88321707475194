@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Ubuntu:wght@700&display=swap");
@import "./globals/beakpoints.scss";

$font__quicksand: "Quicksand", sans-serif;
$font__quicksand-light: 300;
$font__quicksand-regular: 400;
$font__quicksand-medium: 500;
$font__quicksand-semibold: 600;
$font__quicksand-bold: 700;

$font__helvetica: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font__helvetica-bold: bold;
$font__helvetica-regular: 400;

h1 {
  font-family: $font__quicksand;
  font-weight: $font__quicksand-bold;
  font-size: 20px;
  color: #000;
}

/***** NEW FONTS *****/
// font-family: 'Open Sans', sans-serif;
// font-family: 'Ubuntu', sans-serif;

$font__ubuntu: "Ubuntu", sans-serif;
$font__ubuntu-bold: 700;
$font__ubuntu__size--button: 16px;
$font__ubuntu__line-height--button: 22px;
$font__ubuntu__character-spacing--button: 2px;



$font__open-sans: "Open Sans", sans-serif;
$font__open-sans__font-weight--regular: 400;
$font__open-sans__font-weight--bold: 700;
$font__open-sans__size--default: 16px;
$font__open-sans__size--copy: 18px;
$font__open-sans__size--input__placeholder: 18px;
$font__open-sans__line-height--default: 22px;
$font__open-sans__size--label: 15px;
$font__open-sans__line-height--label: 20px;

$font__open-sans__size--small-item: 15px;
$font__open-sans__line-height--small-item: 20px;


@include respond-to("desktop") {
  $font__open-sans__size--default: 18px;
  $font__open-sans__line-height--default: 24px;
  $font__open-sans__size--label: 18px;
  $font__open-sans__line-height--label: 24px;
  $font__ubuntu__size--button: 16px;
  $font__ubuntu__line-height--button: 22px;
  $font__ubuntu__character-spacing--button: 2px;

}
