#invitations-rsvp-notGoing__wrapper {
  background: #fff;
  // display: flex;
  width: 90%;
  max-width: 1200px;
  height: auto;
  align-self: center;
  border-radius: 12px;
  text-align: center;
  padding: 100px;

  #invitations-rsvp-notGoing__wrapper__form__sub-header {
    font-family: $font__helvetica;
    font-weight: $font__helvetica-regular;
    font-size: 20px;
    color: $system-black;
    flex: 1 0 100%;
    text-align: center;
  }
}
