button {
  background-color: $splitsees-purple;
  color: $splitsees-white;
  font-family: $font__ubuntu;
  font-size: $font__ubuntu__size--button;
  line-height: $font__ubuntu__line-height--button;
  letter-spacing: $font__ubuntu__character-spacing--button;
  text-transform: uppercase;
  font-weight: $font__ubuntu-bold;
  border: none;
  padding: 11px 31px;
  border-radius: 27px;
  &.default {
    padding: 11px 31px;
  }
  &.tall {
    padding: 16px 47px;
  }

  span {
    margin-right: 10px;
  }
}
