#merchantProfile__account__wrapper {
  flex: 1 1 100%;
  background-color: $system-white;
  padding: 24px;
  overflow: hidden;

  p.merchantProfile__account__wrapper__copy {
    font-family: $font__quicksand;
    font-weight: $font__quicksand-regular;
    font-size: 13px;
    color: #000;
  }

  p.merchantProfile__account__wrapper__subcopy {
    font-family: $font__helvetica;
    color: $revenue-gray;
    font-size: 14px;
  }
  
  h2 {
    font-family: $font__quicksand;
    font-weight: $font__quicksand-regular;
    font-size: 16px;
    color: #000;

    span.merchantProfile__account__wrapper__edit-sub-copy {
      font-family: $font__helvetica;
      color: $system-blue;
      font-size: 12px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  input {
    font-family: $font__quicksand;
    font-weight: $font__quicksand-semibold;
    color: $system-blue;
    font-size: 13px;
  }
  label {
    font-family: $font__quicksand;
    font-weight: $font__quicksand-semibold;
    color: $system-black;
    font-size: 13px;
  }
}

