.front-door__wrapper {
  background-image: url("https://res.cloudinary.com/dpk0itkv7/image/upload/v1637306411/splitsees__frontdoor--bg_a6c0y7.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  height: 100%;
  // align-content: center;
  justify-content: center;
  padding: 20px;
  @include respond-to("desktop") {
    padding: 2.5% 0;
  }

  .front-door__wrapper__content__wrapper {
    background-color: #fff;
    align-content: center;
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 0 1 100%;
    max-width: 100%;
    flex-wrap: wrap;
    padding: 30px 23px;
    @include respond-to("desktop") {
      flex: 0 1 50%;
      max-width: 50%;
      padding: 0 11%;
    }

    .front-door__logo {
      flex: 0 0 100%;
      margin-bottom: 33px;
    }

    fieldset {
      flex: 0 0 100%;
      border: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 60px;
      .splitsees__inputWrapper{
        flex: 0 0 100%;
        margin-bottom: 18px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      
    }

    .front-door__links--small {
      font-family: $font__open-sans;
      font-size: $font__open-sans__size--small-item;
      line-height: $font__open-sans__line-height--small-item;
      font-weight: $font__open-sans__font-weight--regular;
      text-decoration: underline;
      color: $splitsees-pink;

    }

    .front-door__copy--primary {
      font-family: $font__open-sans;
      font-size: $font__open-sans__size--copy;
      font-weight: $font__open-sans__font-weight--regular;
      color: $splitsees-pink;
      line-height: 24px;
      text-align: center;
    }
  }
}
