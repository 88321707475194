.venue__wrapper {
  border: 1px solid #000;
  flex: 1 0 100%;
  padding: 24px;
  box-sizing: border-box;
  background-size: cover;
  background-position: center;
  border-radius: 12px;
  margin-bottom: 38px;
  display: flex;

  .venue__wrapper__content {
    background-color: rgba(69, 35, 193, 0.78);
    border-radius: 12px;
    flex: 1 0 100%;
    flex-wrap: nowrap;
    display: flex;
    position: relative;
    // justify-content: flex-start;
    // align-items: baseline;
    #eyeIcon {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    .venue__wrapper__content__logo {
      flex: 1 0 25%;
      display: flex;
      .venue__wrapper__content__logoImage {
        width: 100%;
        height: auto;
      }

    }
    .venue__wrapper__content__nameAddress {
      flex: 1 0 25%;
      display: flex;
      align-items: center;
      align-content: center;
      text-align: center;
      flex-wrap: wrap;
      .venue__wrapper__content__nameAddress__name {
        font-family: $font__quicksand;
        font-weight: $font__quicksand-regular;
        font-size: 16px;
        color: $system-black;
        flex: 1 0 100%;

      }
      .venue__wrapper__content__nameAddress__address {
        font-family: $font__helvetica;
        font-weight: $font__helvetica-regular;
        font-size: 14px;
        flex: 1 0 100%;
  
      }
    }
    .venue__wrapper__content__hours {
      flex: 1 0 25%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      align-content: center;
      text-align: center;
      justify-content: center;

      .venue__wrapper__content__hours__weekday {
        font-family: $font__helvetica;
        font-weight: $font__helvetica-regular;
        color: $system-black;
        font-size: 16px;
        flex: 1 0 100%;
      }

      .venue__wrapper__content__hours__time__start,
      .venue__wrapper__content__hours__time__end {
        font-family: $font__helvetica;
        font-weight: $font__helvetica-regular;
        color: $system-black;
        font-size: 14px;
        
      }
    }
    .venue__wrapper__content__website {
      flex: 1 0 25%;
      display: flex;
      align-items: center;

      .venue__wrapper__content__website__link {
        font-family: $font__helvetica;
        font-weight: $font__helvetica-regular;
        color: $system-black;
        font-size: 16px;
      }
    }
    
  }
  
}