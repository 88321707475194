#servicesList__topBar {
  background-color: $system-gray;
  display: flex;
  flex: 1 1 100%;
  border-radius: 12px;
  padding: 15px 19px;
}

#servicesList__wrapper {
  border-radius: 12px;
  background-color: $system-gray ;
  flex: 1 1 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 15px 19px;

  #servicesList__wrapper__header {
    font-family: $font__helvetica;
    font-weight: $font__helvetica-regular;
    font-size: 12px;
    color: $revenue-gray;
    padding: 10px 19px;
    text-align: left;
    display: flex;
    flex-wrap: nowrap;
    // justify-content: space-between;
    justify-content:space-evenly;
    // align-content: space-between;
    flex: 1 0 100%;
    background-color: $system-white;
    border-radius: 12px;

    #servicesList__wrapper__header__id {
      flex: 1 0 20%;
    }
    // #servicesList__wrapper__header__code {
    //   text-align: center;
    //   flex: 1 0 8%;
    // }
    #servicesList__wrapper__header__name {
      flex: 1 0 80%;
    }
    // #servicesList__wrapper__header__status {
    //   text-align: center;
    //   flex: 1 0 10%;
    // }
    // #servicesList__wrapper__header__action {
    //   text-align: center;
    //   flex: 1 0 10%;
    // }
  }
  #servicesList__wrapper__body {
    display: flex;
    flex: 1 0 100%;
    flex-wrap: wrap;
    .servicesList__wrapper__row {
      font-family: $font__helvetica;
      font-weight: $font__helvetica-regular;
      font-size: 12px;
      color: #666;
      padding: 10px 19px;
      text-align: left;
      display: flex;
      flex: 1 0 100%;
      flex-wrap: nowrap;
      justify-content:stretch;
      background-color: $system-white;
      border-radius: 12px;
      margin-top: 14px;
      align-content: center;
      align-items: center;

      .servicesList__wrapper__row__type {
        flex: 1 0 16%;
      }

      .servicesList__wrapper__row__id {
        flex: 1 0 20%;
      }
      .servicesList__wrapper__row__code {
        text-align: center;
        flex: 1 0 8%;
      }
      .servicesList__wrapper__row__name {
        flex: 1 0 80%;
      }
      .servicesList__wrapper__row__status {
        text-align: center;
        flex: 1 0 10%;
      }
      .servicesList__wrapper__row__action {
        text-align: center;
        flex: 1 0 10%;
        color: $system-blue;

        &:hover {
          cursor: pointer;
        }
      }

      &.inactive {
        color: $revenue-gray;
      }

      &.edit {


        .servicesList__wrapper__row__name {
          #serviceName {
            border: none;
            background-color: $revenue-fundraising;
            border-radius: 3px;
            padding: 4px;
            width: 50%;
          }
        }
        .servicesList__wrapper__row__status {
          span {
            background: $revenue-fundraising;
            padding: 4px;
            border-radius: 3px;
            color: $system-black;
            &.activeStatus {
              background-color: $system-blue;
              color: $system-white;
            }
          }
        }
        .servicesList__wrapper__row__action {
          span {
            background-color: $revenue-fundraising;
            padding: 4px;
            color: $system-orange;
            border-radius: 3px;
          }
        }
      }
    }
  }
}

#serviceList__pagination__wrapper {
  display: flex;
  flex-wrap: nowrap;  
  justify-content: center;
  flex: 1 0 100%;
  align-items: center;
  margin: 16px 0 0;

  #pageNumber {
    font-family: $font__helvetica;
    font-weight: $font__helvetica-regular;
    color: $system-black;
    font-size: 16px;
    line-height: 19px;
    margin: 0 42px;
    
  }
}