.front-door__login {
  button {
    margin-bottom: 30px;
  }
  #front-door__login__forgot-password--link {
    margin-left: auto;
    margin-top: 10px;
  }
  #front-door__login__sign-up--link {
    flex: 0 0 100%;
    text-align: center;
  }
}