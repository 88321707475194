@import "./globals/beakpoints.scss";

.splitsees__inputWrapper {
  display: flex;
  min-width: 0px !important;

  label {
    font-family: $font__open-sans;
    font-size: $font__open-sans__size--default;
    color: $splitsees-purple;
    line-height: $font__open-sans__line-height--default;
  }
  input {
    border-bottom: 5px solid $splitsees-purple;
    border-top: none;
    border-left: none;
    border-right: none;
    background-color: #eaeaea;
    height: 48px;
    min-width: 0 !important;
    box-sizing: border-box !important;
    max-width: 100%;


    font-family: $font__open-sans;
    font-size: $font__open-sans__size--default;
    line-height: $font__open-sans__line-height--default;
    color: $splitsees-black;
    &::placeholder {
      color: #aeaeae;
      font-size: $font__open-sans__size--input__placeholder;
    }

    /* Default Text Input Fields */
    &[type="text"] {
      flex: 0 1 100%;
      padding: 10px 18px 11px;
    }

    /* Default Email Input Fields */
    &[type="email"] {
      flex: 0 1 100%;
      padding: 10px 18px 11px;
    }

    /* Default Password Input Fields */ 
    &[type="password"] {
      flex: 0 1 100%;
      padding: 10px 18px 11px;
    }

    /* Default Radio Inputs */
    &[type="radio"] {
      appearance: none;
      background-color: #fff;
      margin: 0 7px 0 0;
      font: inherit;
      color: $splitsees-purple;
      width: $font__open-sans__line-height--default;
      height: $font__open-sans__line-height--default;
      border: 1px solid $splitsees-purple;
      border-radius: 50%;
      display: grid;
      place-content: center;
      @include respond-to("desktop") {
        margin: 0 9px 0 0;
      }

      &::before {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 10px 10px $splitsees-pink;
        @include respond-to("desktop") {
          width: 14px;
          height: 14px;
        }
      }
      &:checked::before {
        transform: scale(1);
      }
    }

    /* Front Door __ Verify __ Authorization Code Field */
    /* Large font size required me to set width, instead of flex basis */
    &#registerVerifyCode {
      // flex: 0 1 100%;
      width: 100%;
      font-size: 55px;
      height: auto;
      &::placeholder {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

/* Input Based Molecules */
.front-door__role-selection {
  display: flex;
  flex: 0 0 100%;
  justify-content: space-evenly;
  align-content: center;
  margin-bottom: 33px;

  @include respond-to("desktop") {
    margin-bottom: 36px;
  }
  span {
    font-family: $font__open-sans;
    color: $splitsees-purple;
    font-size: $font__open-sans__size--default;
    line-height: $font__open-sans__line-height--default;
  }
  
}