#merchantVenueAddDetails__wrapper {
  margin: 57px 31px;

  .merchantVenueAddDetails__inputWrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 0 31px 0 0;

    label {
      font-family: $font__helvetica;
      color: $system-black;
      font-size: 14px;
      line-height: 14px;
      flex: 1 0 100%;
      margin-bottom: 10px;
    }
    input, select {
      flex: 1 0 100%;
      border: 1px solid #90a5b1;
      border-radius: 3px;
      font-family: $font__quicksand;
      font-weight: $font__quicksand-semibold;
      font-size: 13px;
      line-height: 16px;
      padding: 9px 42px 9px 11px;
      margin-bottom: 13px;
      background-color: $system-white;
    }
  }
}
