#inivitations__header {
  width: 90%;
  max-width: 1200px;
  height: 10%;
  display: flex;
  margin: 0 5%;
  align-content: center;
  align-items: center;
}

#invitations__wrapper {
  background: #fff;
  display: flex;
  width: 90%;
  max-width: 1200px;
  height: auto;
  align-self: center;
  border-radius: 12px;
  flex-wrap: wrap;
  padding: 25px 75px;
  align-content: flex-start;

  #invitations__wrapper__first-name {
    font-family: $font__quicksand;
    font-weight: $font__quicksand-regular;
    font-size: 45px;
    line-height: 56px;
    color: $system-black;
    margin-bottom: 19px;
  }

  #invitations__wrapper__sub-header {
    font-family: $font__helvetica;
    font-weight: $font__helvetica-regular;
    font-size: 30px;
    line-height: 35px;
    color: $system-black;
    flex: 1 0 100%;
    margin-bottom: 25px;

    span {
      color: $system-blue;
    }
  }

  #invitations__wrapper__event-details {
    flex: 1 0 50%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 10px 0;

    .invitations__wrapper__event-details__detail-wrapper {
      flex: 1 0 100%;
      flex-wrap: wrap;
      display: flex;

      .invitations__wrapper__event-details__detail-wrapper__label {
        color: $system-blue;
        font-size: 18px;
        font-family: $font__helvetica;
        flex: 1 0 15%;
      }
      .invitations__wrapper__event-details__detail-wrapper__detail {
        color: $system-black;
        font-size:20px;
        font-family: $font__helvetica;
        font-weight: 500;
        flex: 1 0 85%;
      }
    }
  }
  #invitations__wrapper__event-image {
    flex: 1 0 50%;
    padding: 10px 0;

    img {
      width: 100%;
      border-radius: 47px;
    }
  }
  #invitations__wrapper__footer {
    flex: 1 0 100%;
    background: rgba(69, 35, 193, .5);
    display: flex;
    align-items: center;
    padding: 25px;
    margin-top: 25px;
    .invitations__wrapper__footer__detail-wrapper {
      flex: 1 0 50%;

      .invitations__wrapper__footer__detail-wrapper__label {
        color: $system-blue;
        font-size: 18px;
        font-family: $font__helvetica;
        margin-right: 15px;


      }
      .invitations__wrapper__footer__detail-wrapper__detail {
        font-size: 25px;
        font-weight: bold;
        color: $system-black;
        span:first-of-type {
          font-weight: bold;
          font-size: 14px;
          vertical-align: super;
        }
        span:last-of-type {
          font-weight: 400;
          font-size: 11px;
        }
      }
    }
  }

}
