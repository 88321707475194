#invitations-rsvp-going__wrapper {
  background: #fff;
  // display: flex;
  width: 90%;
  max-width: 1200px;
  height: auto;
  align-self: center;
  border-radius: 12px;
  flex-wrap: wrap;
  padding: 25px 75px;
  align-content: flex-start;
  justify-content: center;

  h1 {
    text-align: center;
  }

  #invitations-rsvp-going__wrapper__form {
    width: 50%;
    margin: 0 auto;
    text-align: center;

    #invitations-rsvp-going__wrapper__form__sub-header {
      font-family: $font__helvetica;
      font-weight: $font__helvetica-regular;
      font-size: 20px;
      color: $system-black;
      flex: 1 0 100%;
      text-align: center;
    }

    #cardElement {
      display: block;
      height: auto;
      position: relative;
      padding: 15px;
      box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.50);
      border-radius: 5px;
      margin-top: 50px;
    }

    button {
      background-color: $system-blue;
      color: $system-white;
      font-family: $font__quicksand;
      font-weight: $font__quicksand-regular;
      font-size: 15px;
      border: none;
      border-radius: 3px;
      width: 152px;
      height: 40px;
      margin-top: 20px;
      float: right;
    }
  }
}