#registerLogin__rightSide {
  flex: 1 1 57.75%;
  display: flex;
  background-color: $system-white;
  padding: 34px 40px 70px 69px;
  flex-wrap: wrap;

  #registerLogin_rightSide__topLink {
    font-family: $font__helvetica;
    font-weight: $font__helvetica-regular;
    font-size: 15px;
    line-height: 18px;
    justify-self: right;
    flex: 1 1 100%;
    text-align: right;

    .registerLogin_rightSide__topLink__copy {
      color: $system-black;
      margin-right: 6px;
    }
    .registerLogin_rightSide__topLink__link {
      color: $system-blue;
    }
  }

  #registerLogin__rightSide__registerForm {
    flex: 1 1 100%;
    align-items: flex-start;
    #registerLogin__rightSide__registerForm__copy {


      h1 {
        font-family: $font__quicksand;
        font-weight: $font__quicksand-bold;
        font-size: 33px;
        line-height: 41px;
        color: $system-black;
        margin: 0;
        padding: 0;
      }
      p {
        font-family: $font__helvetica;
        font-weight: $font__helvetica-regular;
        color: $system-black;
        font-size: 15px;
        line-height: 18px;
      }
      ul {
        font-family: $font__helvetica;
        font-weight: $font__helvetica-regular;
        font-size: 16px;
        line-height: 22px;
        color: $createNewService-text-passive;
        list-style: none;
        margin: 31px 0 71px;
        padding: 0;
        border: 1px solid $system-blue;
        border-radius: 3px;
        display: inline-flex;
  
        li {
          padding: 8px 33px;
          &.registerLogin__rightSide__registerForm__merchantPlanner__active {
            color: $system-white;
            background-color: $system-blue;
            border-radius: 3px;
          }
        }
      }
    }
    #registerLogin__rightSide__registerForm__form {
      flex-wrap: wrap;
      display: flex;
      padding-right: 99px;
      .registerLogin__rightSide__registerForm__form__inputWrapper {
        flex: 1 0 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 0 31px 0 0;
        label {
          flex: 1 0 100%;
          margin-bottom: 10px;
        }
        input {
          flex: 1 0 100%;
          border: 1px solid #90A5B1;
          border-radius: 3px;
          font-family: $font__quicksand;
          font-weight: $font__quicksand-semibold;
          font-size: 13px;
          line-height: 16px;
          padding: 9px 42px 9px 11px;
          margin-bottom: 13px;
        }
      }
    } //

    
  }

  #registerLogin__rightSide__registerForm__buttonWrapper {  
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex: 1 0 100%;
  }
}