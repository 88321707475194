#mainContent {
  flex: 1 0 79%;
  background: $system-white;
  border-top-left-radius: 42px;
  display: flex;
  align-items: flex-start;
  padding: 42px;
  overflow: auto;
  height: 100%;
  flex-wrap: wrap;
  align-content: flex-start;
}