@import "../colors.scss";

#rightArrowIcon {
  width: 12px;
  height: 12px;
  fill: #ffffff;
}

#info {
  width: 17px;
  height: 17px;
  fill: #ced5d9;
}

#checkmarkIcon {
  background-color: #50de99;
  width: 12px;
  height: 12px;
  fill: #ffffff;
  box-sizing: content-box;
  border-radius: 50%;

  g {
    path {
      text-indent: 0;
      text-transform: none;
      direction: ltr;

      color: #000000;
      enable-background: accumulate;
    }
  }
}

#userIcon {
  height: 20px;
  width: 20px;
  fill: $iconDefault;

  &.active {
    fill: $iconActive;
  }
}

#bellIcon {
  height: 20px;
  width: 20px;
  fill: $iconDefault;
  shape-rendering: geometricPrecision;
  text-rendering: geometricPrecision;
  image-rendering: optimizeQuality;

  &.active {
    fill: $iconActive;
  }
}

#legalIcon {
  height: 20px;
  width: 20px;
  fill: $iconDefault;
  enable-background: new 0 0 100 100;

  &.active {
    fill: $iconActive;
  }
}

#sidebardDashboardIcon {
  height: 28px;
  width: 28px;
  background-color: $system-blue;
  border: 2px solid $system-blue;
  box-sizing: content-box;
  border-radius: 50%;
  fill: $system-white;

  &.active {
    background-color: $system-gray;
    border-color: $system-gray;
    fill: $system-blue;
  }
}

#sidebarVenueIcon {
  height: 28px;
  width: 28px;
  fill: $system-white;
  shape-rendering: geometricPrecision;
  text-rendering: geometricPrecision;
  image-rendering: optimizeQuality;
  box-sizing: content-box;
  border-radius: 50%;

  background-color: $system-blue;
  border: 2px solid $system-blue;

  &.active {
    background-color: $system-gray;
    border-color: $system-gray;
    fill: $system-blue;
  }
}

#sidebarServicesIcon {
  height: 28px;
  width: 28px;
  fill: $system-white;
  background-color: $system-blue;
  border: 2px solid $system-blue;
  border-radius: 50%;
  box-sizing: content-box;

  &.active {
    border-color: $system-gray;
    background-color: $system-gray;
    fill: $system-blue;
  }
}

#sidebarBookingsIcon {
  height: 28px;
  width: 28px;
  fill: $system-white;
  border: 2px solid $system-blue;
  box-sizing: content-box;
  background-color: $system-blue;
  border-radius: 50%;

  &.active {
    fill: $system-blue;
    border-color: $system-gray;
    background-color: $system-gray;
  }
}

#sidebarExitIcon {
  width: 21px;
  height: 21px;
  fill: $system-white;
}

#imageUploadSuccessCheckmark {
  width: 45px;
  height: 39px;
  fill: #000000;
}

#eyeIcon {
  width: 25px;
  height: 16px;

  path:first-child {
    fill: #f7f7f7;
    fill-opacity: 50%;
  }
  path:last-of-type {
    fill: #ffffff;
    fill-opacity: 67%;
  }
}

#editPencilIcon {
  width: 13px;
  height: 13px;
  background: rgba(207, 207, 207, 0.3);
  fill: $system-blue;
  border: 7.5px solid rgba(207, 207, 207, 0.3);
  box-sizing: content-box;
  border-radius: 50%;
}

#crossCloseIcon {
  width: 14px;
  height: 14px;
  fill: $system-white;
}

#phoneIcon {
  width: 10px;
  width: 10px;
  fill: $system-blue;
}

#emailIcon {
  fill: $system-blue;
  width: 20px;
  height: 20px;
}

#shareIcon {
  height: 15px;
  width: 16px;

  &.transparent {
    fill: #f7f7f7;
    fill-opacity: 46%;
  }

  &.blue {
    fill: $system-blue;
  }
}

#chevronDownIcon {
  width: 16px;
  height: 9px;
  fill: #6d6d6d;
}

#dragAndDropIcon {
  fill: #000;
  width: 88px;
  height: 66px;
}

#throbberIcon {
  fill: $system-orange;
}

#trashIcon {
  fill: $iconDefault;
  width: 10px;
  height: 14px;
}

#inviteIcon {
  width: 16px;
  height: 16px;
  fill: $system-blue;
}

#rsvpYesEmojiIcon {
  width: 16px;
  height: 16px;
  fill: $rsvpYes;
}

#rsvpNoEmojiIcon {
  width: 16px;
  height: 16px;
  fill: $rsvpNo;
}

#rsvpWaitingEmojiIcon {
  width: 16px;
  height: 16px;
  fill: $system-gray;
}

#sidebarEventsIcon {
  height: 28px;
  width: 28px;
  fill: $system-white;
  background-color: $system-blue;
  border: 2px solid $system-blue;
  border-radius: 50%;
  box-sizing: content-box;

  &.active {
    fill: $system-blue;
    border-color: $system-gray;
    background-color: $system-gray;
  }
}

#sidebarContactsIcon {
  height: 28px;
  width: 28px;
  fill: $system-white;
  background-color: $system-blue;
  border: 2px solid $system-blue;
  border-radius: 50%;
  box-sizing: content-box;

  &.active {
    fill: $system-blue;
    border-color: $system-gray;
    background-color: $system-gray;
  }
}

#rightChevronIcon {
  fill: $system-white;
  width: 21.21px;
  &.active {
    fill: $system-blue;
  }
}

#fundedIcon {
  height: 13px;
  width: 13px;
  fill: $system-gray;
  &.active {
    fill: $rsvpYes;
  }
}

#chevronLeftIcon {
  width: auto;
  height: 32px;
  fill: #E5E5E5;
}

#chevronRightIcon {
  width: auto;
  height: 32px;
  fill: #E5E5E5;
}

#rsvpYesOption {
  width: 120px;
  height: 77px;
  g > g {
    fill: #55C1FA;
  }
}

#rsvpNoOption {
  width: 102px;
  height: 78px;
  g > g {
    fill: #55C1FA;
  }
}