@import '/src/globals/fonts.scss';
@import '/src/globals/colors.scss';

#header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $system-blue;

  .header__logoWrapper {
    flex: 1 1 21%;
    display: flex;
    justify-content: center;
    padding: 10px 0;
  }

  .header__profileIconWrapper {
    flex: 1 1 79%;
    flex-direction: row-reverse;
    display: flex;
    padding-right: 40px;

    #header__profileIconWrapper__icon {
      height: 40px;
      width: 40px;
      background-position: center;
      background-size: cover;
      border-radius: 50%;
    }
  }
}