#merchantProfile__notifications__wrapper {
  flex: 1 1 0;
  background-color: $system-white;
  padding: 24px;

  .merchantProfile__notifications__wrapper__checkbox-label {
    font-family: $font__helvetica;
    font-weight: $font__quicksand-regular;
    font-size: 14px;
    color: $system-blue;
  }
}