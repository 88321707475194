#merchantProfile__legal__wrapper {
  flex: 1 1 0;
  background-color: $system-white;
  padding: 24px;
  overflow: hidden;
  display: flex; 
  flex-flow: wrap;
  align-content: flex-start;



  #merchantProfile__legal__wrapper__privacyPolicy {
    background-color: #D5EBF6;
    padding: 10px;
    font-family: $font__quicksand;
    font-weight: $font__quicksand-regular;
    font-size: 20px;
    height: 60%;
    overflow: auto;
  }
}