#invitations-rsvp__wrapper {
  background: #fff;
  display: flex;
  width: 90%;
  max-width: 1200px;
  height: auto;
  align-self: center;
  border-radius: 12px;
  flex-wrap: wrap;
  padding: 25px 75px;
  align-content: flex-start;
  justify-content: center;

  #invitations-rsvp__wrapper__rsvp {
    font-family: $font__quicksand;
    font-weight: $font__quicksand-regular;
    font-size: 45px;
    line-height: 56px;
    color: $system-black;
    margin-bottom: 19px;
    text-align: center;
  }

  #invitations-rsvp__wrapper__subHeader {
    font-family: $font__helvetica;
    font-weight: $font__helvetica-regular;
    font-size: 30px;
    line-height: 35px;
    color: $system-black;
    flex: 1 0 100%;
    margin-bottom: 83px;
    text-align: center;
  }

  #invitations-rsvp__wrapper__rsvp-wrapper {
    flex: 1 0 75%;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 92px;

    .invitations-rsvp__wrapper__rsvp-wrapper__rsvp-option {
      border-radius: 28px;
      border: 5px solid $system-blue;
      padding: 60px 75px 40px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      box-sizing: border-box;

      &:hover, &.active {
        cursor: pointer;
        svg g g {
          fill: #fff;
        }

        span {
          font-weight: bold;
        }
      }

      &:first-of-type:hover, &:first-of-type.active {
        background: rgb(136, 241, 201);
        background: linear-gradient(
          135deg,
          rgba(136, 241, 201, 1) 0%,
          rgba(80, 222, 153, 1) 100%
        );

       
      }

      &:last-of-type:hover, &:last-of-type.active {
        background: rgb(254, 164, 238);
        background: linear-gradient(
          135deg,
          rgba(254, 164, 238, 1) 0%,
          rgba(233, 151, 224, 1) 100%
        );
      }

      svg {
        margin-bottom: 39px;
      }

      span {
        font-family: $font__helvetica;
        font-weight: $font__helvetica-regular;
        font-size: 18px;
        line-height: 22px;
        color: $system-black;
        text-align: center;
        text-transform: uppercase;
      }
    }
  }

  #invitations-rsvp__wrapper__buttons-wrapper {
    flex: 0 0 50%;
    display: flex;
    justify-content: space-evenly;
  }
}
