#registerLogin__wrapper {
  background-color: gray;
  display: flex;
  height: 100%;
  

  #registerLogin__wrapper__leftSide {
    flex: 1 0 47%;
    background-image: url('https://res.cloudinary.com/dpk0itkv7/image/upload/v1623824952/splitsees-app-splash-photo-gradient_whpo2y.jpg');
    background-size: cover;
    background-position: center;
  }
}