.hoursOfOperation__wrapper {
  background-color: $system-white;
  border-radius: 12px;
  margin: 56px 152px;
  padding: 20px;
  h2 {
    font-family: $font__helvetica;
    font-weight: $font__helvetica-bold;
    color: $system-black;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  }
  label {
    font-family: $font__helvetica;
    font-weight: $font__helvetica-regular;
    font-size: 14px;
    line-height: 17px;
    color: $system-black;
  }
  span {
    font-family: $font__helvetica;
    font-weight: $font__helvetica-regular;
    font-size: 12px;
    line-height: 14px;
    color: $system-black;
  }
  .hoursOfOperation__wrapper__day__wrapper {
    justify-content: space-between;
    display: flex;
    padding: 10px 0;
  }

  .hoursOfOperation__wrapper__buttons__wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
  }

}
