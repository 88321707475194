#merchantBookings__wrapper {
  flex: 1 0 100%;
  border-radius: 12px;
  background-color: $system-gray;
  padding: 27px;

  #merchantBookings__wrapper__topBar {
    background-color: $system-gray;
    display: flex;
    flex: 1 1 100%;
    border-radius: 12px;
    padding: 15px 19px;
  }

 
  #merchantBookings__wrapper__List__wrapper__header {
    font-family: $font__helvetica;
    font-weight: $font__helvetica-regular;
    font-size: 12px;
    color: $revenue-gray;
    padding: 10px 19px;
    text-align: left;
    display: flex;
    flex-wrap: nowrap;
    // justify-content: space-between;
    justify-content:space-evenly;
    // align-content: space-between;
    flex: 1 0 100%;
    background-color: $system-white;
    border-radius: 12px;

    #merchantBookings__wrapper__List__wrapper__header__id {
      flex: 1 0 25%;
    }
    // #servicesList__wrapper__header__code {
    //   text-align: center;
    //   flex: 1 0 8%;
    // }
    #merchantBookings__wrapper__List__wrapper__header__name {
      flex: 1 0 25%;
    }
    #merchantBookings__wrapper__List__wrapper__header__date{
      flex: 1 0 25%;
    }
    #merchantBookings__wrapper__List__wrapper__header__status{
      flex: 1 0 25%;
    }
    // #servicesList__wrapper__header__status {
    //   text-align: center;
    //   flex: 1 0 10%;
    // }
    // #servicesList__wrapper__header__action {
    //   text-align: center;
    //   flex: 1 0 10%;
    // }
  }
  #merchantBookings__wrapper__body {
    display: flex;
    flex: 1 0 100%;
    flex-wrap: wrap;
    .merchantBookings__wrapper__row {
      font-family: $font__helvetica;
      font-weight: $font__helvetica-regular;
      font-size: 12px;
      color: #666;
      padding: 10px 19px;
      text-align: left;
      display: flex;
      flex: 1 0 100%;
      flex-wrap: nowrap;
      justify-content:stretch;
      background-color: $system-white;
      border-radius: 12px;
      margin-top: 14px;
      align-content: center;
      align-items: center;

      .merchantBookings__wrapper__row__id {
        flex: 1 0 25%;
      }

      .merchantBookings__wrapper__row__date {
        flex: 1 0 25%;
      }
      .merchantBookings__wrapper__row__name {
        // text-align: center;
        flex: 1 0 25%;
      }
      .merchantBookings__wrapper__row__status {
        flex: 1 0 25%;
        text-transform: capitalize;

        span {
          color: $system-blue;
          margin: 0 10px;
          text-decoration: underline;
        }
      }


      &.inactive {
        color: $revenue-gray;
      }

      &.edit {


        .servicesList__wrapper__row__name {
          #serviceName {
            border: none;
            background-color: $revenue-fundraising;
            border-radius: 3px;
            padding: 4px;
            width: 50%;
          }
        }
        .servicesList__wrapper__row__status {
          span {
            background: $revenue-fundraising;
            padding: 4px;
            border-radius: 3px;
            color: $system-black;
            &.activeStatus {
              background-color: $system-blue;
              color: $system-white;
            }
          }
        }
        .servicesList__wrapper__row__action {
          span {
            background-color: $revenue-fundraising;
            padding: 4px;
            color: $system-orange;
            border-radius: 3px;
          }
        }
      }
    }
  }
}