@import '/src/globals/fonts.scss';
@import '/src/globals/colors.scss';

.revenue__wrapper {
  background-color: $system-white;
  display: flex;
  flex-wrap: nowrap;
  padding: 20px;

  .revenue__graph {
    flex: 3 1 75%;

    .revenue__graph__scale{
      height: 25%;
    }
    .revenue__graph__bars{
      position: relative;
      height: 50%;
      padding-right: 50px;
      display: flex;
      align-items: center;
      

      &::before {
        content: '';
        height: 35px;
        background-color: $revenue-bar-ground;
        width: 5px;
        display: block;
        position: absolute;
        border-radius: 10px;
      }
      
      .revenue__graph__bars_paid-bar {
        width: 25%;
        height: 35px;
        background-color: $system-orange;
        border-radius: 20px;
        position: absolute;
        margin-left: 20px;
      }
      .revenue__graph__bars_fundraising-bar {
        height: 35px;
        width: 100%;
        background-color: $revenue-fundraising;
        border-radius: 20px;
        margin-left: 20px;
      }
    }
    .revenue__graph__legend{
      height: 25%;

      ul {
        display: flex;
        list-style: inside;
        padding: 0;
        
        li {
          font-size: 20px; 
          margin-right: 20px;
          
          &:first-of-type {
            color: $system-orange;
          }

          &:last-of-type {
            color: $revenue-fundraising;
          }
          
          span {
            font-size: 10px;
            left: -10px;
            top: -2px;
            position: relative;
            color: $revenue-gray;            
          }
        }
      }
    }

  }
  .revenue__totals {
    flex: 1 1 25%;
    display: flex;
    flex-wrap: nowrap;
    text-align: center;
    justify-items: center;
    justify-content: center;
    align-items: center;
    align-content: center;
    
    

    .revenue__totals__paid {
      flex: 1 1 50%;
      border: 4px solid $system-gray;
      border-radius: 5px;
      margin-right: 6px;
      display: flex;
      justify-items: center;
      justify-content: center;
      align-items: center;
      align-content: center;
      flex-wrap: wrap;
      position: relative;

      &::before {
        content: '';
        float: left;
        padding-top: 100%;
      }

      .revenue__totals__paid-content {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        justify-items: center;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;

        .revenue__totals__currency {
          font-size: 16px;
          font-family: $font__quicksand;
          
        }
        .revenue__totals__total-paid {
          font-size: 18px;
          color: $system-orange;
          font-family: $font__quicksand;
          font-weight: $font__quicksand-bold;
          
        }
        .revenue__totals__label {
          font-size: 8px;
          font-family: $font__quicksand;
          flex: 1 1 100%;
        }
      }
      
    }
    .revenue__totals__fundraising {
      flex: 1 1 50%;
      border: 4px solid $system-gray;
      border-radius: 5px;
      margin-left: 6px;
      display: flex;
      flex-wrap: wrap;
      position: relative;

      &::before {
        content: '';
        float: left;
        padding-top: 100%;
      }

      .revenue__totals__fundraising-content {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        justify-items: center;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;

        .revenue__totals__currency {
          font-size: 16px;
          font-family: $font__quicksand;
        }
        .revenue__totals__total-fundraising {
          font-size: 18px;
          font-family: $font__quicksand;
          color: $system-blue;
          font-weight: $font__quicksand-bold;
        }
        .revenue__totals__label {
          font-size: 8px;
          font-family: $font__quicksand;
          flex: 1 1 100%;
        }
      }
    }
  }
}
