#registerLogin__rightSide__registerForm__form {
  #verifyInput {
    font-size: 69px;
    width: 50%;
    margin-top: 110px;
  }
  p {
    flex: 1 0 100%;
    color: $system-black;
    font-weight: $font__quicksand-regular;
    margin-bottom: 50px;

  }
  .input-wrapper{
    flex: 1 0 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 0 31px 0 0;
        label {
          flex: 1 0 100%;
          margin-bottom: 10px;
        }
        input {
          flex: 1 0 100%;
          border: 1px solid #90A5B1;
          border-radius: 3px;
          font-family: $font__quicksand;
          font-weight: $font__quicksand-semibold;
          font-size: 13px;
          line-height: 16px;
          padding: 9px 42px 9px 11px;
          margin-bottom: 13px;
        }
  }
  
}

#registerLogin__rightSide__registerForm__buttonWrapper {
  button {
    margin-left: 18px;
  }
}
