#modal__wrapper {
  position: relative;
  z-index: 1;

  #modal__wrapper_content {
    position: absolute;
    width: 50vw;
    min-height: 25vh;
    left: 25vw;
    top: 37.5vh;
    background-color: $system-white;
    padding: 25px;
    box-sizing: border-box;
    display: flex;
    border-radius: 12px;
    flex-wrap: wrap;

    #modal__wrapper_content_title {
      font-family: $font__quicksand;
      font-size: 20px;
      font-weight: $font__quicksand-semibold;
      color: $system-black;
      flex: 1 0 100%;
    }

    #modal__wrapper_content_copy {
      font-family: $font__quicksand;
      font-size: 13px;
      font-weight: 400;
      color: $system-black;
      flex: 1 0 100%;
    }

    #modal__wrapper_conent_message-error {
      p {
        color: #000;
      }
    }

    Button {
      margin-left: auto;
      align-self: flex-end;
    }
  }

  &::before {
    content: '';
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
  }

}