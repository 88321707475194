@import '/src/globals/fonts.scss';
@import '/src/globals/colors.scss';

#profileCard {
  background-color: $system-white;
  border-radius: 6px;
  padding: 62px 24px 45px;
  max-width: 248px;
  min-width: 248px;
  text-align: center;
  display: flex;



  #profileCard__contentWrapper {
    background-color: #F4F5F4;
    border-radius: 6px;
    flex: 1 0 100%;
    align-content: center;
    padding: 0 12.5%;


    #profileCard__contentWrapper__profilePhoto {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: -42px;
      position: relative;
      margin-bottom: 17px;
      width: 150px;
      height: 150px;
      border-radius: 50%;
      background-position: center;
      background-size: cover;
      border: 7px solid $system-white;
      box-sizing: border-box;

      // &::before {
      //   content:'';
      //   width:100%;
      //   height: 100%;
      //   z-index: 8;
        
      //   position: absolute;
      //   border-radius: 50%;
      // }

      &:hover::after {
        content:'upload';
        font-family: $font__helvetica;
        font-weight: $font__helvetica-bold;
        color: $system-white;
        font-size: 12px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.50);
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        box-sizing: border-box;
        z-index: 9;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        border-radius: 50%;
        width: 100%;
        
        border: 7px solid $system-white;
        position: relative;
        z-index: 5;
      }
      span {
        font-family: $font__helvetica;
        font-weight: $font__helvetica-bold;
        color: $system-black;
        font-size: 12px;
        flex: 1 0 100%;
      }
    }

    .profileCard__contentWrapper__name {
      font-family: $font__helvetica;
      font-weight: $font__helvetica-bold;
      font-size: 18px;
    }

    #profileCard__contentWrapper__linkList {
      margin: 0;
      padding: 0;
      text-align: left;
      font-family: $font__quicksand;
      font-weight: $font__quicksand-regular;
      font-size: 16px;
      color: $revenue-gray;
      list-style-type: none;
      margin-top: 50px;

      li:hover {
        cursor: pointer;
      }
    }
  }

}